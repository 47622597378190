import React from 'react';
import './App.css';
import { makeIdea } from "./data/idea";

const amount = "Billion";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            idea: "Roll For New Ideas!",
        }
    }

    componentDidMount() {
        this.el = document.getElementById("content-box");

        console.log(this.el);
    }

    handleClick = () => {
        this.setState({
            idea: makeIdea(),
        });

        this.toggleAnimation();
    };

    toggleAnimation = () => {
        this.el.classList.add("app-animation");

        if (this.el.classList.contains("app-animation")) {
            setTimeout(() => this.el.classList.remove("app-animation"), 500);
        }
    };

    render() {
        return (
          <div className="app">
            <div className="container">
              <header className="row d-flex justify-content-center">
                <div className="app-header">Next {amount} Dollar Idea</div>
              </header>
              <div className="row d-flex justify-content-center">
                <div
                  id="content-box"
                  className="d-flex align-items-center justify-content-center app-content"
                >
                  {this.state.idea}
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-danger btn-lg app-btn"
                  onClick={this.handleClick}
                >
                  <h2>Roll !</h2>
                </button>
              </div>
            </div>
          </div>
        );
    }
}

export default App;
