export const verbs = [
    "let",
    "make",
    "teach",
];

export const nons = [
    "cat",
    "dog",
    "turtle",
    "pets",
];

export const endings = [
    "drive",
    "eat",
    "run",
    "tweet",
    "drink",
    "think",
    "yawn",
    "yarn",
    "walk",
];